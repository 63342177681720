import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import { graphql } from 'gatsby';
import ArticlePreview from '../components/blog/article-preview.js';
import Tag from '../components/blog/tag.js';
import { Seo } from '../components/layout/seo';
import styled from '@emotion/styled';

const BlogPage = (props) => {
  const posts = props.data.allContentfulBlogPost.nodes;
  const getTags = () => {
    const tags = posts.map((post) => {
      return post.tags;
    });
    const Tags = Array.prototype.concat(...tags);
    Tags.push('All');
    Tags.sort();
    return [...new Set(Tags)];
  };
  const filters = getTags();
  const [tag, setTag] = useState('All');

  const filterPosts = (posts, tag) => {
    return posts.filter((post) => {
      if (tag === 'All') return post;
      else if (post.tags.includes(tag)) return post;
      return;
    });
  };
  return (
    <Layout>
      <Container>
        <PageTitle>The STASIS Rehab Blog</PageTitle>
        <FlexBox>
          <Posts>
            <Title>RECENTLY PUBLISHED</Title>
            <ArticlePreview posts={filterPosts(posts, tag)} />
          </Posts>
          <Categories>
            <Title>CATEGORIES</Title>
            <Tags>
              {filters.map((filter) => {
                return (
                  <Tag
                    selected={tag === filter ? true : false}
                    tag={filter}
                    onClick={() => setTag(filter)}
                  >
                    {filter}
                  </Tag>
                );
              })}
            </Tags>
          </Categories>
        </FlexBox>
      </Container>
    </Layout>
  );
};
export const Head = (location) => (
  <Seo
    title="Stasis Blog"
    description="Read articles and blog-posts written by our own practitioners"
    pathname={location.pathname}
  ></Seo>
);
export default BlogPage;
export const pageQuery = graphql`
  query BlogIndex {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        heroImage {
          description
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        tags
      }
    }
  }
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 2vw calc((100vw - 1200px) / 2);
  height: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;

const FlexBox = styled.div`
  width: 100%;
  display: flex;

  margin-bottom: 40px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Tags = styled.small`
  display: flex;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  min-height: 200px;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (max-width: 1200px) {
    height: auto;
    min-height: 0;
    gap: 10px;
  }
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: black;
  margin-bottom: 10px;
  @media (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

const Posts = styled.div`
  flex: 0 0 65%;
  display: flex;
  flex-direction: column;
  align-content: left;
  @media (max-width: 1200px) {
    order: 2;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  align-content: left;
  @media (max-width: 1200px) {
    order: 1;
  }
`;

const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #084f87;
  margin: 20px 0 30px;
`;
