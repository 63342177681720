import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import Tags from './tags.js';

const Img = styled(GatsbyImage)`
  flex: 0 0 20%;
  height: 130px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  flex: 0 0 80%;
  padding-left: 20px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  height: 120px;
  flex-direction: column;
  justify-content: space-between;
`;

const List = styled.ul`
  list-style: none;
  width: 100%;
  padding-left: 0px;
`;

const BlogLink = styled(Link)`
  all: unset;
  cursor: pointer;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  ${BlogLink}:hover & {
    text-decoration: underline;
  }
`;

const Element = styled.li`
  margin-top: 20px;
  padding: 10px;
  padding-left: 0px;
  :first-child {
    margin-top: 0px;
  }
  @media (max-width: 1200px) {
    margin-top: 25px;
  }
`;
const Date = styled.small`
  font-style: italic;
`;
const ArticlePreview = ({ posts }) => {
  if (!posts) return null;
  if (!Array.isArray(posts)) return null;

  return (
    <List>
      {posts.map((post) => {
        return (
          <Element key={post.slug}>
            <BlogLink to={`/blog/${post.slug}/`}>
              <Flex>
                <Img alt={``} image={post.heroImage.gatsbyImageData} />
                <Text>
                  <Title>{post.title}</Title>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.description.childMarkdownRemark.html,
                    }}
                  />
                  <Date>{post.publishDate}</Date>
                  <Tags tags={post.tags} />
                </Text>
              </Flex>
            </BlogLink>
          </Element>
        );
      })}
    </List>
  );
};

export default ArticlePreview;
